const transformProductResponse = response => {
  const {data, included} = response;

  const productImages = included?.filter(item => item.type === 'image');
  const productVariants = included?.filter(item => item.type === 'variant');
  const productReviews = included?.filter(item => item.type === 'review');
  const taxonData = included?.filter(item => item.type === 'taxon');
  const optionValues = included?.filter(item => item.type === 'option_value');
  const totalRating = data?.attributes?.total_rating;

  const variantsWithImagesAndOptions = productVariants?.map(variant => {
    const optionValueIds = variant.relationships.option_values.data.map(
      ov => ov.id,
    );

    const variantOptionValues = optionValues.filter(ov =>
      optionValueIds.includes(ov.id),
    );

    const variantImageIds = variant?.relationships?.images?.data?.map(
      imgRel => imgRel.id,
    );

    const variantImages = productImages?.filter(image =>
      variantImageIds?.includes(image.id),
    );

    const combinedImages = [
      ...variantImages.map(img => img?.attributes?.original_url),
    ];

    return {
      id: variant?.id,
      attributes: {
        ...variant?.attributes,
        images: combinedImages,
        option_values: variantOptionValues.map(option => ({
          id: option.id,
          name: option.attributes.name,
          presentation: option.attributes.presentation,
        })),
      },
    };
  });

  const taxonInfo = taxonData?.map(taxon => ({
    id: taxon.id,
    name: taxon?.attributes?.name,
    prettyName: taxon?.attributes?.pretty_name,
    permaLink: taxon?.attributes?.permalink,
  }));

  const reviewsInfo = productReviews?.map(review => ({
    reviewerName: review?.attributes?.user
      ? `${review?.attributes?.user?.first_name} ${review?.attributes?.user?.last_name}`
      : review?.attributes?.reciever_full_name,
    reviewerRate: parseInt(review?.attributes?.rating),
    reviewerComment: review?.attributes?.comment,
    reply: review?.attributes?.reply
      ? {
          body: review.attributes.reply.body,
          adminName: review.attributes.reply.admin_name,
          createdAt: review.attributes.reply.created_at,
          edited: review.attributes.reply.edited,
          editedAt: review.attributes.reply.edited_at,
        }
      : null,
  }));

  const totalReviews = data?.relationships?.reviews?.data?.length;

  const ratingCounts = Array(5)
    .fill(0)
    .map((_, idx) => {
      const count = productReviews?.filter(
        review => parseInt(review.attributes.rating) === 5 - idx,
      ).length;
      return {
        rating: 5 - idx,
        count,
        percentage: Math.round((count / totalReviews) * 100),
      };
    });

  return {
    productImages,
    productVariants: variantsWithImagesAndOptions,
    productReviews,
    productInfo: {
      id: data?.id,
      name: data?.attributes?.name,
      metaTitle: data?.attributes?.meta_title,
      metaDesc: data?.attributes?.meta_description,
      metaKeyWords: data?.attributes?.meta_keywords,
      image: productImages?.[0]?.attributes?.original_url,
      price: data?.attributes?.price,
      totalRating: totalRating,
      productSlug: data?.attributes?.slug,
      productDesc: data?.attributes?.description,
      productCareTips: data?.attributes?.care_tips,
    },
    totalReviews,
    ratingPercentages: ratingCounts.reduce(
      (acc, {rating, percentage}) => ({
        ...acc,
        [`totalReviewsWithRating${rating}Percentage`]: percentage,
      }),
      {},
    ),
    reviewsInfo,
    taxonInfo,
  };
};

export default transformProductResponse;
