import {api} from './api';
import {
  CreditCardTransformer,
  favoritesTransformer,
  transformOrderResponse,
  transformOrdersResponse,
  transformPaymentMethodsResponse,
  transformReceiverOrderResponse,
  transformUserWithAddresses,
  userStatesSerializer,
} from './serializers';

export const userApi = api.injectEndpoints({
  endpoints: build => ({
    getUser: build.query({
      query: params => ({
        url: 'api/v2/storefront/account',
        method: 'GET',
        params,
      }),
    }),
    getUserAddresses: build.query({
      query: params => ({
        url: '/api/v2/storefront/account/addresses',
        method: 'GET',
        params,
      }),
      providesTags: ['Address'],
    }),
    updateUserAddress: build.mutation({
      query: params => ({
        url: `/api/v2/storefront/account/addresses/${params?.id}`,
        method: 'PATCH',
        body: params?.body,
      }),
      invalidatesTags: ['Address', 'User'],
    }),
    addUserAddress: build.mutation({
      query: params => ({
        url: `/api/v2/storefront/account/addresses`,
        method: 'POST',
        body: params?.body,
      }),
      invalidatesTags: ['Address', 'User'],
    }),
    deleteUserAddress: build.mutation({
      query: id => ({
        url: `/api/v2/storefront/account/addresses/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Address', 'User'],
    }),
    getUserCreditCards: build.query({
      query: params => ({
        url: 'api/v2/storefront/account/credit_cards',
        method: 'GET',
        params,
      }),
      transformResponse: CreditCardTransformer,
      providesTags: ['Card'],
    }),
    updateCreditName: build.mutation({
      query: body => ({
        url: `api/v2/storefront/account/credit_cards/${body.id}`,
        method: 'PATCH',
        body: body.data,
      }),
      invalidatesTags: ['Card'],
    }),
    deleteCreditCard: build.mutation({
      query: creditCardId => ({
        url: `api/v2/storefront/account/credit_cards/${creditCardId}`,
        method: 'Delete',
      }),
      invalidatesTags: ['Card'],
    }),
    getUserWithAddresses: build.query({
      query: (extraParams = {}) => ({
        url: 'api/v2/storefront/account',
        method: 'GET',
        params: {
          include: 'default_shipping_address',
          ...extraParams,
        },
      }),
      providesTags: ['User'],
      transformResponse: transformUserWithAddresses,
    }),
    updateUser: build.mutation({
      query: data => ({
        url: 'api/v2/storefront/account',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    updateAnAddress: build.mutation({
      query: data => ({
        url: `api/v2/storefront/account/addresses/${data.address.id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    getCountryStates: build.query({
      query: ({countryIso, ...extraParams}) => ({
        url: `api/v2/storefront/countries/${countryIso}`,
        method: 'GET',
        params: {
          include: 'states',
          ...extraParams,
        },
      }),
      transformResponse: userStatesSerializer,
    }),
    getOrders: build.query({
      query: params => ({
        url: '/api/v2/storefront/account/orders',
        method: 'GET',
        params,
      }),
      transformResponse: transformOrdersResponse,
    }),
    getOrder: build.query({
      query: ({orderNumber, params}) => ({
        url: `/api/v2/storefront/account/orders/${orderNumber}`,
        method: 'GET',
        params,
      }),
      transformResponse: transformOrderResponse,
    }),
    getCreditCards: build.query({
      query: params => ({
        url: `/api/v2/storefront/account/credit_cards`,
        method: 'GET',
        params,
      }),
    }),
    tapCallBack: build.mutation({
      query: data => ({
        url: `/api/v2/storefront/account/credit_cards`,
        method: 'POST',
        body: data,
      }),
    }),
    getPaymentMethods: build.query({
      query: params => ({
        url: `/api/v2/storefront/checkout/payment_methods`,
        method: 'GET',
        params,
      }),
      transformResponse: transformPaymentMethodsResponse,
    }),
    getAllPaymentMethods: build.query({
      query: params => ({
        url: `/api/v2/storefront/checkout/payment_methods`,
        method: 'GET',
        params,
        headers: {
          'X-Spree-Order-Token': params?.token,
        },
      }),
      transformResponse: transformPaymentMethodsResponse,
    }),
    createNewPayment: build.mutation({
      query: data => ({
        url: `/api/v2/storefront/checkout/create_payment`,
        method: 'POST',
        body: data,
        params: {
          include: 'payments',
        },
      }),
    }),
    createPayment: build.mutation({
      query: data => ({
        url: `/api/v2/storefront/checkout/create_payment`,
        method: 'POST',
        body: data?.body,
        params: {
          include: 'payments',
        },
        headers: {
          'X-Spree-Order-Token': data?.token,
        },
      }),
    }),
    payWithStoreCredits: build.mutation({
      query: data => ({
        url: `/api/v2/storefront/checkout/add_store_credit`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
    payAsGuest: build.mutation({
      query: data => ({
        url: `/api/v2/storefront/tap_payment_charges`,
        method: 'POST',
        body: data,
      }),
    }),
    contactUs: build.mutation({
      query: data => ({
        url: `api/v2/storefront/contact_us`,
        method: 'POST',
        body: data,
      }),
    }),
    getReceiverOrder: build.query({
      query: params => ({
        url: '/api/v2/storefront/orders/receiver_order',
        method: 'GET',
        params,
      }),
      transformResponse: transformReceiverOrderResponse,
    }),
    getOrCreateFavoritesList: build.query({
      query: params => ({
        url: '/api/v2/storefront/wishlists/default',
        method: 'GET',
        params: {
          locale: params.locale,
          'filter[branches]': params.branchId,
          include:
            'wished_items.variant.product.images,wished_items.variant.product.taxons',
        },
      }),
      transformResponse: favoritesTransformer,
      providesTags: ['Favorites'],
    }),
    addItemToFav: build.mutation({
      query: params => ({
        url: `/api/v2/storefront/wishlists/${params.token}/add_item`,
        method: 'POST',
        params: {
          variant_id: params.itemId,
        },
      }),
      invalidatesTags: ['Favorites'],
    }),
    removeItemFromFav: build.mutation({
      query: params => ({
        url: `/api/v2/storefront/wishlists/${params.token}/remove_item/${params.itemId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Favorites'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUserAddressesQuery,
  useUpdateUserAddressMutation,
  useAddUserAddressMutation,
  useDeleteUserAddressMutation,
  useGetUserWithAddressesQuery,
  useUpdateUserMutation,
  useUpdateAnAddressMutation,
  useGetCountryStatesQuery,
  useGetOrdersQuery,
  useGetOrderQuery,
  useGetUserCreditCardsQuery,
  useLazyGetUserCreditCardsQuery,
  useDeleteCreditCardMutation,
  useUpdateCreditNameMutation,
  useGetCreditCardsQuery,
  useTapCallBackMutation,
  useCreateNewPaymentMutation,
  useContactUsMutation,
  useGetReceiverOrderQuery,
  usePayWithStoreCreditsMutation,
  usePayAsGuestMutation,
  useGetPaymentMethodsQuery,
  useLazyGetPaymentMethodsQuery,
  useCreatePaymentMutation,
  useGetAllPaymentMethodsQuery,
  useGetOrCreateFavoritesListQuery,
  useLazyGetOrCreateFavoritesListQuery,
  useAddItemToFavMutation,
  useRemoveItemFromFavMutation,
} = userApi;
