import {api} from './api';
import {transformCartResponse} from './serializers';

export const cartEndpoints = api.injectEndpoints({
  endpoints: build => ({
    getCart: build.query({
      query: (extraParams = {}) => ({
        url: 'api/v2/storefront/cart',
        method: 'GET',
        params: {
          include:
            'line_items.quantity,variants.images,variants.product.images,user,promotions,branch',
          ...extraParams,
        },
      }),
      transformResponse: transformCartResponse,
    }),
    associateCart: build.mutation({
      query: params => ({
        url: 'api/v2/storefront/cart/associate',
        method: 'PATCH',
        params,
      }),
    }),
    createCart: build.mutation({
      query: data => ({
        url: 'api/v2/storefront/cart',
        method: 'POST',
        body: data,
      }),
      transformResponse: transformCartResponse,
    }),
    addToCart: build.mutation({
      query: data => ({
        url: 'api/v2/storefront/cart/add_item',
        method: 'POST',
        body: data,
        params: {
          include: 'line_items.quantity,variants.images',
        },
      }),
      transformResponse: transformCartResponse,
    }),
    updateCartQuantity: build.mutation({
      query: data => ({
        url: 'api/v2/storefront/cart/set_quantity',
        method: 'PATCH',
        body: data,
        params: {
          include: 'line_items.quantity,variants.images',
        },
      }),
      transformResponse: transformCartResponse,
    }),
    deleteFromCart: build.mutation({
      query: id => ({
        url: `api/v2/storefront/cart/remove_line_item/${id}`,
        method: 'DELETE',
        params: {
          include: 'line_items.quantity,variants.images',
        },
      }),
      transformResponse: transformCartResponse,
    }),
    updateCheckout: build.mutation({
      query: data => ({
        url: `/api/v2/storefront/checkout`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Cart'],
    }),
    getAllDeliverySlots: build.query({
      query: params => ({
        url: `/api/v2/storefront/working_days/best_slots`,
        method: 'GET',
        params: params,
      }),
    }),
    createReview: build.mutation({
      query: data => ({
        url: `/api/v2/storefront/reviews`,
        method: 'POST',
        body: data,
      }),
    }),
    nextCheckoutStep: build.mutation({
      query: () => ({
        url: `/api/v2/storefront/checkout/next`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Cart'],
    }),
    completeCheckout: build.mutation({
      query: () => ({
        url: `/api/v2/storefront/checkout/complete`,
        method: 'PATCH',
      }),
    }),
    updateReceiverShippingAddress: build.mutation({
      query: data => ({
        url: `/api/v2/storefront/orders/receiver_info`,
        method: 'PUT',
        body: data,
      }),
    }),
    getOrderStatus: build.query({
      query: ({orderNumber, ...extraParams}) => {
        return {
          url: `/api/v2/storefront/order_status/${orderNumber}`,
          method: 'GET',
          params: {
            include: 'delivery_slot,variants.product',
            ...extraParams,
          },
        };
      },
    }),
    sendWrittenCardData: build.mutation({
      query: data => {
        const isFormData = data instanceof FormData;

        return {
          url: '/api/v2/storefront/written_cards',
          method: 'POST',
          body: data,
          headers: {
            Accept: 'application/vnd.api+json',
            ...(isFormData ? undefined : {'Content-Type': 'application/json'}),
          },
          params: {include: 'order'},
        };
      },
      params: {
        include: 'delivery_slot',
      },
    }),
    addCouponCode: build.mutation({
      query: body => ({
        url: '/api/v2/storefront/cart/apply_coupon_code',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Cart'],
    }),
    removeCouponCode: build.mutation({
      query: couponCode => ({
        url: `/api/v2/storefront/cart/remove_coupon_code/${couponCode}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Cart'],
    }),
    cancelOrder: build.mutation({
      query: body => ({
        url: `/api/v2/storefront/orders/cancel`,
        method: 'PUT',
        body,
      }),
    }),
    reOrder: build.mutation({
      query: body => ({
        url: `api/v2/storefront/orders/${body?.orderNumber}/reorder`,
        method: 'POST',
      }),
      transformResponse: transformCartResponse,
    }),
    getOrderPreparationAbility: build.query({
      query: () => {
        return {
          url: `/api/v2/storefront/cart/preparation_ability`,
          method: 'GET',
        };
      },
    }),
    getUnpaidOrderPreparationAbility: build.query({
      query: data => {
        return {
          url: `/api/v2/storefront/cart/preparation_ability`,
          method: 'GET',
          headers: {
            'X-Spree-Order-Token': data?.token,
          },
        };
      },
    }),
    getUnpaidOrder: build.query({
      query: data => ({
        url: 'api/v2/storefront/cart',
        method: 'GET',
        params: data?.params,
        headers: {
          'X-Spree-Order-Token': data?.token,
        },
      }),
      transformResponse: transformCartResponse,
    }),
    checkoutWithTamara: build.mutation({
      query: body => ({
        url: `api/v2/storefront/tamara_checkout`,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCartQuery,
  useAssociateCartMutation,
  useCreateCartMutation,
  useAddToCartMutation,
  useUpdateCartQuantityMutation,
  useDeleteFromCartMutation,
  useUpdateCheckoutMutation,
  useGetAllDeliverySlotsQuery,
  useLazyGetAllDeliverySlotsQuery,
  useCreateReviewMutation,
  useNextCheckoutStepMutation,
  useCompleteCheckoutMutation,
  useUpdateReceiverShippingAddressMutation,
  useGetOrderStatusQuery,
  useLazyGetOrderStatusQuery,
  useSendWrittenCardDataMutation,
  useAddCouponCodeMutation,
  useRemoveCouponCodeMutation,
  useCancelOrderMutation,
  useLazyGetOrderPreparationAbilityQuery,
  useReOrderMutation,
  useGetUnpaidOrderQuery,
  useLazyGetUnpaidOrderPreparationAbilityQuery,
  useCheckoutWithTamaraMutation,
} = cartEndpoints;
