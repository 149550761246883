const transformPaymentMethodsResponse = response => {
  const {data} = response;

  const creditPaymentMethod = data?.find(
    method => method.attributes?.type === 'Spree::Gateway::TapPaymentGateway',
  );
  const guestPaymentMethod = data?.find(
    method =>
      method.attributes?.type === 'Spree::Gateway::GuestTapPaymentGateway',
  );
  const applePaymentMethod = data?.find(
    method => method.attributes?.type === 'Spree::Gateway::ApplePayGateway',
  );
  const tamaraPaymentMethod = data?.find(
    method =>
      method.attributes?.type === 'Spree::Gateway::TamaraPaymentGateway',
  );

  return {
    creditPaymentMethod,
    guestPaymentMethod,
    applePaymentMethod,
    tamaraPaymentMethod,
  };
};

export default transformPaymentMethodsResponse;
