const transformProductsResponse = response => {
  const {data, included} = response;

  const optionValues = extractOptionValues(included);
  const images = extractImages(included);
  const variants = extractVariants(included, optionValues, images);

  const products = data.map(product => {
    const productImages = getProductImages(product, images);
    const variantImageIds = getVariantImageIds(variants);

    const productOnlyImages = filterProductImages(
      productImages,
      variantImageIds,
    );

    let productVariants = getProductVariants(
      product,
      variants,
      productOnlyImages,
    );

    if (productVariants.length === 0) {
      productVariants = handleNoVariants(product, variants, productOnlyImages);
    }

    return {
      id: product.id,
      name: product.attributes.name,
      slug: product.attributes.slug,
      price: parseFloat(product.attributes.price),
      displayPrice: product.attributes.display_price,
      currency: product.attributes.currency,
      purchasable: product.attributes.purchasable,
      inStock: product.attributes.in_stock,
      localizedSlugs: product.attributes.localized_slugs,
      totalRating: product.attributes.total_rating,
      variants: productVariants,
      images: productImages,
      description: product.attributes.description,
    };
  });

  return {products};
};

const extractOptionValues = included =>
  included
    .filter(item => item.type === 'option_value')
    .map(option => ({
      id: option.id,
      name: option.attributes.name,
      presentation: option.attributes.presentation,
    }));

const extractImages = included =>
  included
    .filter(item => item.type === 'image')
    .map(image => ({
      id: image.id,
      originalUrl: image.attributes.original_url,
    }));

const extractVariants = (included, optionValues, images) =>
  included
    .filter(item => item.type === 'variant')
    .map(variant => {
      const optionValueIds = variant.relationships.option_values.data.map(
        optionValue => optionValue.id,
      );
      const variantOptionValues = optionValues.filter(option =>
        optionValueIds.includes(option.id),
      );

      return {
        id: variant.id,
        sku: variant.attributes.sku,
        optionsText: variant.attributes.options_text,
        purchasable: variant.attributes.purchasable,
        inStock: variant.attributes.in_branch_stock,
        price: parseFloat(variant.attributes.price),
        displayPrice: variant.attributes.display_price,
        variantImages: getVariantImages(variant, images),
        optionValues: variantOptionValues,
      };
    });

const getVariantImages = (variant, images) =>
  variant.relationships.images.data.map(imageRel =>
    images.find(image => image.id === imageRel.id),
  );

const getProductImages = (product, images) =>
  product.relationships.images.data.map(imageRel =>
    images.find(image => image.id === imageRel.id),
  );

const getVariantImageIds = variants =>
  variants.flatMap(variant => variant.variantImages).map(image => image.id);

const filterProductImages = (productImages, variantImageIds) =>
  productImages.filter(
    productImage => !variantImageIds.includes(productImage.id),
  );

const getProductVariants = (product, variants, productOnlyImages) =>
  product.relationships.variants.data.map(variantRel => {
    const variant = variants.find(v => v.id === variantRel.id);
    const mergedImages = [...variant.variantImages, ...productOnlyImages];
    return {...variant, mergedImages};
  });

const handleNoVariants = (product, variants, productOnlyImages) => {
  const primaryVariantId = product.relationships.primary_variant?.data?.id;
  const defaultVariantId = product.relationships.default_variant?.data?.id;
  const variantId = primaryVariantId || defaultVariantId;

  if (variantId) {
    const variant = variants.find(v => v.id === variantId);
    const mergedImages = [...variant.variantImages, ...productOnlyImages];
    return [{...variant, mergedImages}];
  }

  return [];
};

export default transformProductsResponse;
