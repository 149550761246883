const transformUserWithAddresses = response => {
  const {data} = response;

  const user = {
    id: data?.id,
    birthday: data?.attributes.birthday,
    firstName: data?.attributes.first_name,
    lastName: data?.attributes.last_name,
    phoneNumber: data?.attributes.phone_number,
    walletBalance: data?.attributes.store_credits,
    profileImage: data?.attributes.image_url,
    gender: data?.attributes.gender,
    email: data?.attributes.email,
    city: data?.attributes.city,
    isUserVerified: data?.attributes.is_mobile_verified,
  };

  return {user};
};
export default transformUserWithAddresses;
