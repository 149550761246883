function favoritesTransformer(response) {
  const {data, included} = response;

  const wishlistToken = data.attributes.token;

  const products = included.filter(item => item.type === 'product');
  const variants = included.filter(item => item.type === 'variant');
  const taxonData = included?.filter(item => item.type === 'taxon');
  const wishedItems = included.filter(item => item.type === 'wished_item');
  const images = included.filter(item => item.type === 'image');

  const serializedProducts = wishedItems.map(wishedItem => {
    const variantId = wishedItem.relationships.variant.data.id;

    const variantDetails = variants.find(variant => variant.id === variantId);
    const productId = variantDetails?.relationships?.product?.data?.id;
    const productDetails = products?.find(product => product.id === productId);
    const getProductImages = (product, images) =>
      product.relationships.images.data.map(imageRel =>
        images.find(image => image.id === imageRel.id),
      );
    const getProductTaxon = (product, taxons) =>
      product.relationships.taxons.data.map(taxonAllData =>
        taxons.find(taxon => taxon.id === taxonAllData.id),
      );

    const productImages = getProductImages(productDetails, images);
    const productTaxon = getProductTaxon(productDetails, taxonData);

    return {
      wishedItemId: wishedItem.id,
      quantity: wishedItem.attributes.quantity,
      product: {
        id: productDetails?.id,
        name: productDetails?.attributes?.name,
        description: productDetails?.attributes?.description,
        sku: productDetails?.attributes?.sku,
        slug: productDetails?.attributes?.slug,
        price: productDetails?.attributes?.price,
        displayPrice: productDetails?.attributes?.display_price,
        inStock: productDetails?.attributes?.in_stock,
        inBranchStock: productDetails?.attributes?.in_branch_stock,
        totalRating: productDetails?.attributes?.total_rating,
        images: productImages,
        taxonInfo: productTaxon,
      },
      variant: {
        id: variantDetails?.id,
        sku: variantDetails?.attributes?.sku,
        options: variantDetails?.attributes?.options_text,
        price: variantDetails?.attributes?.price,
        displayPrice: variantDetails?.attributes?.display_price,
        inStock: variantDetails?.attributes?.in_stock,
      },
    };
  });

  return {
    wishlistToken,
    products: serializedProducts,
  };
}

export default favoritesTransformer;
