const transformPickupStoresResponse = response => {
  if (!response || !response.data) return [];

  const {data} = response;

  return data.map(item => {
    const attributes = item.attributes;
    return {
      id: attributes.id,
      name: attributes.name,
      latitude: parseFloat(attributes.latitude),
      longitude: parseFloat(attributes.longitude),
      deliveryTime: attributes.delivery_time,
      warehouse: attributes.warehouse,
      deliveryZone: {
        id: attributes.delivery_zone.id,
        name: attributes.delivery_zone.name,
        active: attributes.delivery_zone.active,
      },
      address: attributes.address,
    };
  });
};

export default transformPickupStoresResponse;
