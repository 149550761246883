module.exports = {
  product1: require('./images/product1.png'),
  product2: require('./images/product2.png'),
  plus: require('./images/plus.png'),
  minus: require('./images/minus.png'),
  logo: require('./images/logo.png'),
  recorder: require('./images/recorder.png'),
  link: require('./images/link.png'),
  bouquet: require('./images/bouquet.png'),
  loading: require('./images/loading.png'),
  saffron: require('./images/saffron.png'),
  phone_label: require('./images/saudiFlag.svg'),
  credit_card: require('./images/credit.svg'),
  visa: require('./images/visa.svg'),
  video: require('./images/video.png'),
  spLogo: require('./images/SPLogo.png'),
  sr_map: require('./images/sr_map.png'),
  onePay: require('./images/1Pay.png'),
  mada: require('./images/mada.png'),
  paymentLogo: require('./images/paymentLogo.png'),
  stc: require('./images/stc.png'),
  visa: require('./images/whiteVisaLogo.png'),
  loadingPayment: require('./images/loadingPayment.png'),
  branch: require('./images/branch.png'),
  footer_flowers: require('./images/footer_flowers.svg'),
  flower: require('./images/flower.svg'),
  masterCard: require('./images/masterCard.png'),
  cardChip: require('./images/cardChip.png'),
  signal: require('./images/signal.png'),
  emptyCart: require('./images/emptyCart.svg'),
  floatingFlowers: require('./images/floatingFlowers.png'),
  backFlower: require('./images/backFlower.png'),
  extraordinaryFlowers: require('./images/extraordinaryFlowers.jpg'),
  singleBackFlower: require('./images/single_back_flower.png'),
  thirdFlower: require('./images/third_flower.png'),
  categoryImage: require('./images/categoryImage.png'),
  applePay: require('./images/applePay.png'),
  faq: require('./images/faq.jpg'),
  about: require('./images/about.jpg'),
  policy: require('./images/policy.jpg'),
  warning: require('./images/warning.png'),
  success: require('./images/success.png'),
  planning: require('./images/planning.jpg'),
  logo_ar: require('./images/logo-sr-arabic.png'),
  logo_en: require('./images/logo-sr-english.svg'),
  share: require('./images/share.svg'),
  google: require('./images/google.png'),
  mapPin: require('./images/mapPin.svg'),
  phone: require('./images/phone.svg'),
  truck: require('./images/truck.svg'),
  anooshPartner: require('./images/anooshPartner.svg'),
  erminePartner: require('./images/erminePartner.svg'),
  godivaPartner: require('./images/godivaPartner.svg'),
  patchiPartner: require('./images/patchiPartner.svg'),
  arrangment: require('./images/arrangment.png'),
  flowerFood: require('./images/flowerFood.svg'),
  carbon: require('./images/carbon.svg'),
  expressDelivery: require('./images/expressDelivery.svg'),
  giftWrapping: require('./images/giftWrapping.svg'),
  grower: require('./images/grower.svg'),
  namedDay: require('./images/namedDay.svg'),
  heart: require('./images/heart.svg'),
  lens: require('./images/lens.svg'),
  profile: require('./images/profile.svg'),
  cart: require('./images/cart.svg'),
  facebook: require('./images/facebook.png'),
  eventsHome: require('./images/eventsHome.png'),
  flowersSub: require('./images/flowersSub.png'),
  weekly: require('./images/weekly.png'),
  lavivianePartner: require('./images/lavivianePartner.jpeg'),
  saudiFlagRounded: require('./images/saudiFlagRounded.svg'),
  rightCategoryFlower: require('./images/rightCategoryFlower.webp'),
  leftCategoryFlower: require('./images/leftCategoryFlower.webp'),
  tamaraEnBadge: require('./images/tamara-logo-badge-en.png'),
  tamaraArBadge: require('./images/tamara-logo-badge-ar.png'),
};
