const transformOrdersResponse = response => {
  const {data, included} = response;

  const addresses = included
    ?.filter(item => item.type === 'address')
    ?.reduce((acc, address) => {
      acc[address.id] = address.attributes;
      return acc;
    }, {});

  const lineItems = included
    ?.filter(item => item.type === 'line_item')
    ?.reduce((acc, lineItem) => {
      acc[lineItem.id] = lineItem.attributes;
      return acc;
    }, {});

  const orders = data?.map(order => ({
    ...order,
    shippingAddresses: addresses[order.relationships.shipping_address.data.id],
    lineItems: order.relationships.line_items.data.map(
      lineItem => lineItems[lineItem.id],
    ),
  }));

  return {orders};
};
export default transformOrdersResponse;
