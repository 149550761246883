const announcementTransformer = response => {
  const {data} = response;

  const announcements = data?.map(item => ({
    id: item.id,
    sourceType: item.attributes.source_type,
    sourceId: item.attributes.source_id,
    availableFrom: item.attributes.available_from,
    availableTo: item.attributes.available_to,
    webImageUrl: item.attributes.image_url?.original,
    phoneImageUrl: item.attributes.phone_image_url?.original,
    webImageUrlPlaceholder: item.attributes.image_url?.medium,
    phoneImageUrlPlaceholder: item.attributes.phone_image_url?.medium,
    announcementSlug: item.attributes.slug,
    permalink: item.attributes.permalink,
  }));

  return announcements;
};

export default announcementTransformer;
