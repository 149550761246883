const transformCartResponse = response => {
  const {data, included} = response;

  const images = included
    ?.filter(item => item.type === 'image')
    ?.map(image => ({
      id: image.id,
      originalUrl: image.attributes.original_url,
    }));

  const variants = included
    ?.filter(item => item.type === 'variant')
    ?.map(variant => ({
      id: variant.id,
      sku: variant.attributes.sku,
      optionsText: variant.attributes.options_text,
      purchasable: variant.attributes.purchasable,
      inStock: variant.attributes.in_stock,
      price: parseFloat(variant.attributes.price),
      displayPrice: variant.attributes.display_price,
      variantImages: variant.relationships.images.data.map(imageRel =>
        images.find(image => image.id === imageRel.id),
      ),
    }));

  const productImages = included
    ?.filter(item => item.type === 'product')
    ?.map(p => ({
      images: p.relationships.images.data.map(imageRel =>
        images.find(image => image.id === imageRel.id),
      ),
    }));

  const lineItems = included
    ?.filter(item => item.type === 'line_item')
    ?.map(lineItem => {
      const variant = variants.find(
        variant => variant.id === lineItem.relationships.variant.data.id,
      );
      return {
        id: lineItem.id,
        name: lineItem.attributes.name,
        quantity: lineItem.attributes.quantity,
        price: parseFloat(lineItem.attributes.price),
        displayPrice: lineItem.attributes.display_price,
        total: parseFloat(lineItem.attributes.total),
        productImages,
        displayTotal: lineItem.attributes.display_total,
        optionsText: lineItem.attributes.options_text,
        variant: {
          ...variant,
          images: variant?.variantImages,
        },
      };
    });

  const preparationTimes = included
    ?.filter(item => item.type === 'product')
    ?.map(item => item.attributes.preparation_time)
    .filter(time => time !== null)
    .sort((a, b) => b - a);

  const maxPreparationTime = preparationTimes?.[0] || 0;

  const firstWrittenCard = included
    ?.filter(item => item.type === 'taxonomy')
    ?.find(item => item.attributes.name.toLowerCase() === 'gift cards');

  const couponCodes = included
    ?.filter(item => item.type === 'promotion')
    ?.map(promo => ({
      id: promo.id,
      name: promo.attributes.name,
      code: promo.attributes.code,
      amount: parseFloat(promo.attributes.amount),
      displayAmount: promo.attributes.display_amount,
    }));

  const branch = included?.find(item => item.type === 'branch');

  const shoppingMethod = included?.find(
    item => item.type === 'shopping_method',
  );

  const user = included?.find(item => item.type === 'user');

  const cart = {
    id: data.id,
    number: data.attributes.number,
    itemTotal: parseFloat(data.attributes.item_total),
    total: parseFloat(data.attributes.total),
    shipTotal: parseFloat(data.attributes.ship_total),
    adjustmentTotal: parseFloat(data.attributes.adjustment_total),
    createdAt: data.attributes.created_at,
    updatedAt: data.attributes.updated_at,
    includedTaxTotal: parseFloat(data.attributes.included_tax_total),
    additionalTaxTotal: parseFloat(data.attributes.additional_tax_total),
    taxTotal: parseFloat(data.attributes.tax_total),
    currency: data.attributes.currency,
    state: data.attributes.state,
    token: data.attributes.token,
    itemCount: data.attributes.item_count,
    displayPreTaxTotal: data.attributes.display_pre_tax_total,
    displayTotal: data.attributes.display_total,
    discountAmount: data.attributes.display_promo_total,
    lineItems: lineItems,
    maxPreparationTime,
    scheduleTime: data.attributes.schedule_time,
    hasWrittenCard: !!firstWrittenCard,
    couponCodes: couponCodes,
    branchDeliveryTime: branch?.attributes.delivery_time,
    isDelivery: shoppingMethod?.attributes.delivery,
    user: {
      id: user?.attributes?.tap_payment_customer_id || '',
      name: [
        {
          first: user?.attributes?.first_name || 'N/A',
          last: user?.attributes?.last_name || 'N/A',
        },
      ],
      contact: {
        email: user?.attributes?.email || 'support@springrose.com',
        phone: {
          countryCode: '966',
          number:
            user?.attributes?.phone_number.replace(/^966/, '') || '545833108',
        },
      },
    },
  };

  return cart;
};

export default transformCartResponse;
