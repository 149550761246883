const nearestStoreSerializer = response => {
  const store = response?.data;

  return {
    id: store?.attributes.id,
    name: store?.attributes.name,
    externalId: store?.attributes.external_id,
    deliveryTime: store?.attributes.delivery_time,
    storeAddress: store?.attributes.address,
    location: {
      latitude: store?.attributes.latitude,
      longitude: store?.attributes.longitude,
    },
    warehouse: store?.attributes.warehouse,
    deliveryZone: {
      id: store?.attributes.delivery_zone?.id,
      name: store?.attributes.delivery_zone?.name,
      active: store?.attributes.delivery_zone?.active,
    },
    timestamps: {
      createdAt: store?.attributes.created_at,
      updatedAt: store?.attributes.updated_at,
    },
  };
};

export default nearestStoreSerializer;
