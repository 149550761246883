import {api} from './api';
import {
  announcementTransformer,
  categoriesAndTheirChildrenTransformer,
  nearestStoreSerializer,
  transformAddOnsResponse,
  transformCardDesignsResponse,
  transformPickupStoresResponse,
  transformProductResponse,
  transformProductsResponse,
  transformShoppingMethodsResponse,
} from './serializers';

export const productApi = api.injectEndpoints({
  endpoints: build => ({
    getAllProducts: build.query({
      query: params => ({
        url: `/api/v2/storefront/products`,
        method: 'GET',
        params,
      }),
      providesTags: ['Products'],
    }),
    getAddOns: build.query({
      query: (extraParams = {}) => ({
        url: `/api/v2/storefront/products`,
        method: 'GET',
        params: {
          include: 'images,taxons,default_variant',
          ...extraParams,
        },
      }),
      transformResponse: transformAddOnsResponse,
    }),
    getAllFreshPicksProducts: build.query({
      query: (extraParams = {}) => ({
        url: `/api/v2/storefront/products`,
        method: 'GET',
        params: {
          include:
            'variants,images,variants.images,variants.option_values,default_variant',
          'filter[taxon_name]': 'Fresh Picks',
          ...extraParams,
        },
      }),
      transformResponse: transformProductsResponse,
    }),
    getAllBestSelling: build.query({
      query: (extraParams = {}) => ({
        url: `/api/v2/storefront/products`,
        method: 'GET',
        params: {
          include:
            'variants,images,variants.images,variants.option_values,default_variant',
          ...extraParams,
        },
      }),
      transformResponse: transformProductsResponse,
    }),
    getAllNewArrivals: build.query({
      query: (extraParams = {}) => ({
        url: `/api/v2/storefront/products`,
        method: 'GET',
        params: {
          include:
            'variants,images,variants.images,variants.option_values,default_variant',
          ...extraParams,
        },
      }),
      transformResponse: transformProductsResponse,
    }),
    getProduct: build.query({
      query: data => ({
        url: `/api/v2/storefront/products/${data?.id}`,
        method: 'GET',
        params: data?.params,
      }),
      transformResponse: transformProductResponse,
    }),
    getSuggestedProducts: build.query({
      query: data => ({
        url: `/api/v2/storefront/products`,
        method: 'GET',
        params: data?.params,
      }),
    }),
    getCardDesigns: build.query({
      query: (extraParams = {}) => ({
        url: `/api/v2/storefront/products`,
        method: 'GET',
        params: {
          include: 'images,variants,default_variant',
          'filter[taxon_name]': 'Gift Cards',
          ...extraParams,
        },
      }),
      transformResponse: transformCardDesignsResponse,
    }),
    getAllCategories: build.query({
      query: params => ({
        url: `/api/v2/storefront/taxons`,
        method: 'GET',
        params,
      }),
      keepUnusedDataFor: 10 * 60 * 1000, // 10 minutes
    }),
    getAllCategoriesWithTheirChildren: build.query({
      query: (restParams = {}) => ({
        url: `/api/v2/storefront/taxons`,
        method: 'GET',
        params: {
          'filter[roots]': true,
          include: 'children.image,children.children.image,taxonomy',
          ...restParams,
        },
      }),
      transformResponse: categoriesAndTheirChildrenTransformer,
      keepUnusedDataFor: 10 * 60 * 1000, // 10 minutes
    }),
    getCategory: build.query({
      query: ({id, ...restParams}) => ({
        url: `/api/v2/storefront/taxons`,
        method: 'GET',
        params: {'filter[ids]': id, ...restParams},
      }),
    }),
    getAllSubCategories: build.query({
      query: ({parentId, ...extraParams}) => ({
        url: `/api/v2/storefront/taxons`,
        method: 'GET',
        params: {
          include: 'image,children',
          'filter[parent_id]': parentId,
          ...extraParams,
        },
      }),
    }),
    retrieveTaxon: build.query({
      query: param => ({
        url: `/api/v2/storefront/taxons/${param.id}`,
        method: 'GET',
        params: {
          locale: param.locale,
          include: 'taxonomy,image',
        },
      }),
    }),
    getFilterOptions: build.query({
      query: ({id, ...extraParams}) => ({
        url: `/api/option_types/${id}`,
        method: 'GET',
        params: {...extraParams},
      }),
    }),
    getTaxonProducts: build.query({
      query: params => ({
        url: `/api/taxons/products`,
        params,
        method: 'GET',
      }),
    }),
    getAllFilters: build.query({
      query: params => ({
        url: `/api/v2/storefront/option_types`,
        method: 'GET',
        params,
      }),
    }),
    getAllShoppingMethods: build.query({
      query: data => ({
        url: `/api/v2/storefront/shopping_methods`,
        method: 'GET',
        params: {locale: data?.currentLocale, ...data?.params},
      }),
      transformResponse: transformShoppingMethodsResponse,
    }),
    getAllZones: build.query({
      query: data => ({
        url: `/api/v2/storefront/delivery_zones`,
        method: 'GET',
        params: {locale: data?.currentLocale},
      }),
    }),
    getClosestBranches: build.query({
      query: data => ({
        url: `/api/v2/storefront/branches/closest_branch`,
        method: 'GET',
        params: data?.body,
      }),
      invalidatesTags: ['Products'],
    }),
    getNearestStore: build.query({
      query: params => ({
        url: `api/v2/storefront/branches/closest_branch`,
        method: 'GET',
        params,
      }),
      transformResponse: nearestStoreSerializer,
    }),
    getPickupStores: build.query({
      query: params => ({
        url: `/api/v2/storefront/branches`,
        method: 'GET',
        params,
      }),
      transformResponse: transformPickupStoresResponse,
    }),
    getAnnouncements: build.query({
      query: params => ({
        url: `api/v2/storefront/announcements`,
        method: 'GET',
        params,
      }),
      transformResponse: announcementTransformer,
    }),
    getAllEvents: build.query({
      query: params => ({
        url: `api/v2/storefront/events`,
        method: 'GET',
        params,
      }),
    }),
    getEvent: build.query({
      query: ({id, params}) => ({
        url: `api/v2/storefront/events/${id}`,
        method: 'GET',
        params,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAllProductsQuery,
  useGetFilterOptionsQuery,
  useGetTaxonProductsQuery,
  useLazyGetAllProductsQuery,
  useGetAllCategoriesQuery,
  useGetAllFiltersQuery,
  useGetAllSubCategoriesQuery,
  useGetCategoryQuery,
  useGetProductQuery,
  useGetSuggestedProductsQuery,
  useGetAddOnsQuery,
  useGetAllFreshPicksProductsQuery,
  useGetAllBestSellingQuery,
  useLazyGetClosestBranchesQuery,
  useGetAllZonesQuery,
  useGetNearestStoreQuery,
  useLazyGetNearestStoreQuery,
  useGetCardDesignsQuery,
  useGetAllShoppingMethodsQuery,
  useGetAnnouncementsQuery,
  useRetrieveTaxonQuery,
  useLazyRetrieveTaxonQuery,
  useLazyGetProductQuery,
  useGetPickupStoresQuery,
  useGetAllEventsQuery,
  useGetEventQuery,
  useGetAllCategoriesWithTheirChildrenQuery,
  useGetAllNewArrivalsQuery,
} = productApi;
