const userStatesSerializer = response => {
  const states = response.data.relationships.states.data.map(stateRel => {
    const state = response.included.find(
      item => item.id === stateRel.id && item.type === stateRel.type,
    );
    return {
      id: state.id,
      name: state.attributes.name,
    };
  });
  return states;
};
export default userStatesSerializer;
