const categoriesAndTheirChildrenTransformer = response => {
  const {data, included} = response;

  const taxonImageMap = included
    ?.filter(item => item.type === 'taxon_image')
    ?.reduce((acc, image) => {
      acc[image.id] = {
        id: image.id,
        originalUrl: image.attributes.original_url,
        styles: image.attributes.styles,
      };
      return acc;
    }, {});

  const taxonomyMap = included
    ?.filter(item => item.type === 'taxonomy')
    ?.reduce((acc, taxonomy) => {
      acc[taxonomy.id] = {
        id: taxonomy.id,
        name: taxonomy.attributes.name,
        position: taxonomy.attributes.position,
      };
      return acc;
    }, {});

  const subCategoryMap = included
    ?.filter(item => item.type === 'taxon')
    ?.reduce((acc, subCategory) => {
      acc[subCategory.id] = {
        id: subCategory.id,
        name: subCategory.attributes.name,
        description: subCategory.attributes.description,
        pretty_name: subCategory.attributes.pretty_name,
        permaLink: subCategory.attributes.permalink,
        position: subCategory.attributes.position,
        taxonomyPosition: subCategory.relationships.taxonomy?.data
          ? taxonomyMap[subCategory.relationships.taxonomy.data.id]?.position
          : null,
        subCategoryImage: subCategory.relationships.image?.data
          ? taxonImageMap[subCategory.relationships.image.data.id]
          : null,
        children:
          subCategory.relationships.children?.data?.map(child => child.id) ||
          [],
      };
      return acc;
    }, {});

  const populateChildren = subcategoryId => {
    const subcategory = subCategoryMap[subcategoryId];
    if (subcategory && subcategory.children.length > 0) {
      subcategory.children = subcategory.children.map(childId =>
        populateChildren(childId),
      );
    }
    return subcategory;
  };

  const categories = data
    ?.filter(item => item.type === 'taxon' && item.attributes.is_main)
    ?.map(category => {
      const mainChildren =
        category.relationships.children?.data?.map(child =>
          populateChildren(child.id),
        ) || [];

      const allChildren =
        mainChildren.length > 0 ? mainChildren[0]?.children || [] : [];

      return {
        id: category.id,
        categoryName: category.attributes.name,
        prettyCategoryname: category.attributes.pretty_name,
        position: category.attributes.position,
        permaLink: category.attributes.permalink,
        taxonomyPosition: category.relationships.taxonomy?.data
          ? taxonomyMap[category.relationships.taxonomy.data.id]?.position
          : null,
        children: [...mainChildren, ...allChildren],
        categoryImage: category.relationships.image?.data
          ? taxonImageMap[category.relationships.image.data.id]
          : null,
        isOccasionsCategory: category.attributes.occasions,
      };
    })
    .sort((a, b) => {
      if (a.taxonomyPosition !== b.taxonomyPosition) {
        return a.taxonomyPosition - b.taxonomyPosition;
      }
      return a.position - b.position;
    });

  return categories;
};

export default categoriesAndTheirChildrenTransformer;
