const transformReceiverOrderResponse = response => {
  if (!response || !response.data) return {};

  const {data, included} = response;

  const includedItems = type => {
    return included.filter(item => item.type === type);
  };

  return {
    id: data.id,
    products: includedItems('product').map(item => ({
      id: item.id,
      name: item.attributes.name,
    })),
    shippingAddress: includedItems('address').map(address => ({
      firstName: address.attributes.firstname,
      lastName: address.attributes.lastname,
      phone: address.attributes.phone,
      address: address.attributes.address1,
      city: address.attributes.city,
      countryName: address.attributes.country_name,
    })),
    driver: includedItems('driver').map(driver => ({
      id: driver.attributes.id,
    })),
    isDelivery: includedItems('shopping_method')?.[0]?.attributes?.delivery,
  };
};

export default transformReceiverOrderResponse;
