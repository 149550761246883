const transformAddOnsResponse = response => {
  const {data, included} = response;

  const productVariants =
    included?.filter(item => item.type === 'variant') || [];
  const taxonData = included?.filter(item => item.type === 'taxon') || [];
  const imageMap = included
    ?.filter(item => item.type === 'image')
    ?.reduce((map, image) => {
      map[image.id] = image;
      return map;
    }, {});

  const taxons = taxonData.map(taxon => {
    const taxonId = taxon.id;

    const products = data
      .filter(
        product => product.relationships?.taxons?.data?.[0]?.id === taxonId,
      )
      .map(product => {
        const productId = product.id;

        const imageIds =
          product.relationships?.images?.data?.map(imgRel => imgRel.id) || [];
        const images = imageIds
          .map(imageId => imageMap[imageId])
          .filter(Boolean);

        const variants = productVariants.filter(
          variant => variant.relationships?.product?.data?.id === productId,
        );

        const isInStock = product.attributes?.in_stock;
        if (images.length > 0 && isInStock) {
          return {
            id: productId,
            ...product.attributes,
            images,
            variants,
          };
        }

        return null;
      })
      .filter(Boolean);
    return {
      id: taxonId,
      ...taxon.attributes,
      products,
    };
  });

  return {
    taxons,
  };
};

export default transformAddOnsResponse;
