const transformShoppingMethodsResponse = response => {
  const {data, included} = response;

  const nonDeliveryShoppingMethods = data.filter(
    shoppingMethod => !shoppingMethod.attributes.delivery,
  );

  const branchIds = nonDeliveryShoppingMethods.flatMap(method =>
    method.relationships.branches.data.map(branch => branch.id),
  );

  const pickupStores = included.filter(
    item => item.type === 'branch' && branchIds.includes(item.id),
  );

  return {
    data,
    pickupStores,
  };
};

export default transformShoppingMethodsResponse;
