const transformOrderResponse = response => {
  if (!response || !response.data) return {};

  const {data, included} = response;
  const order = data.attributes;

  const includedItems = type => {
    return included?.filter(item => item.type === type);
  };

  return {
    id: data.id,
    itemTotal: order.item_total,
    subTotal: order.pre_tax_total,
    taxTotal: order.tax_total,
    shipmentState: order.shipment_state,
    preparingState: order.preparing,
    shoppingMethod: data.relationships.shopping_method.data.id,
    images: includedItems('image')?.map(image => image.attributes.original_url),
    lineItems: includedItems('line_item')?.map(item => ({
      id: item.id,
      quantity: item.attributes.quantity,
      name: item.attributes.name,
      color: item.attributes.options_text.split(': ')[1],
      price: item.attributes.total,
    })),
    variants: includedItems('variant').map(item => ({
      id: item.id,
    })),
    products: includedItems('product').map(item => ({
      id: item.id,
      name: item.attributes.name,
    })),
    paymentMethods: includedItems('payment').map(
      payment => payment.attributes.payment_method_name,
    ),
    shippingAddress: includedItems('address')?.map(address => ({
      firstName: address.attributes.firstname,
      lastName: address.attributes.lastname,
      phone: address.attributes.phone,
      address: address.attributes.address1,
      city: address.attributes.city,
      countryName: address.attributes.country_name,
    })),
  };
};

export default transformOrderResponse;
