const transformCardDesignsResponse = response => {
  const {data, included} = response;

  const designUrls = included
    .filter(item => item.type === 'image')
    .map(image => image.attributes.original_url);

  let freeCard = data.find(product => Number(product.attributes.price) === 0);

  const freeCardId =
    freeCard?.relationships?.variants?.data?.[0] ||
    freeCard?.relationships?.default_variant?.data;

  const freeCardImage = included.find(
    item => item.id === freeCard.relationships.images.data[0].id,
  );
  const freeCardUrl = freeCardImage?.attributes?.original_url || null;

  const cardsWithPrices = data.map(product => {
    const productImage = included.find(
      item => item.id === product.relationships.images.data[0].id,
    );

    const allVariants = included?.filter(item => item.type === 'variant') || [];
    const variantIds = product.relationships.variants.data.map(
      variant => variant.id,
    );
    const defaultVariantId = product.relationships.default_variant?.data?.id;
    const variants = allVariants
      .filter(
        variant =>
          variantIds.includes(variant.id) || variant.id === defaultVariantId,
      )
      .map(variant => ({
        id: variant.id,
        ...variant.attributes,
      }));

    return {
      price: product.attributes.price,
      original_url: productImage?.attributes?.original_url || null,
      variants,
    };
  });

  return {
    designUrls,
    freeCardUrl,
    cardsWithPrices,
    freeCardId,
  };
};

export default transformCardDesignsResponse;
