import {api} from './api';
export const authApi = api.injectEndpoints({
  endpoints: build => ({
    login: build.mutation({
      query: credentials => ({
        url: 'spree_oauth/token',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['User', 'Favorites'],
    }),
    signup: build.mutation({
      query: credentials => ({
        url: 'api/v2/storefront/account',
        method: 'POST',
        body: credentials,
      }),
    }),
    socialMediaAuth: build.mutation({
      query: credentials => ({
        url: '/spree_oauth/token',
        method: 'POST',
        body: credentials,
      }),
    }),
    logout: build.mutation({
      query: token => ({
        url: 'spree_oauth/revoke',
        method: 'POST',
        body: token,
      }),
    }),

    requestUserOtp: build.mutation({
      query: body => ({
        url: 'api/v2/storefront/users/request_otp',
        method: 'POST',
        body,
      }),
    }),
    verifyUserOtp: build.mutation({
      query: body => ({
        url: 'api/v2/storefront/users/verify_otp',
        method: 'POST',
        body,
      }),
    }),
    requestGuestOtp: build.mutation({
      query: body => ({
        url: 'api/v2/storefront/guest_otps/request_guest_otp',
        method: 'POST',
        body,
      }),
    }),
    VerifyGuestOtp: build.mutation({
      query: body => ({
        url: 'api/v2/storefront/guest_otps/verify_guest_otp',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: build.mutation({
      query: body => ({
        url: 'api/v2/storefront/users/reset_password',
        method: 'PATCH',
        body,
      }),
    }),
    forgetPassword: build.mutation({
      query: body => ({
        url: 'api/v2/storefront/users/update_password',
        method: 'PATCH',
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useLoginMutation,
  useSignupMutation,
  useLogoutMutation,
  useRequestUserOtpMutation,
  useVerifyUserOtpMutation,
  useRequestGuestOtpMutation,
  useVerifyGuestOtpMutation,
  useResetPasswordMutation,
  useForgetPasswordMutation,
  useSocialMediaAuthMutation,
} = authApi;
