'use client';

import {IoStar, IoStarHalf} from 'react-icons/io5';
import * as Yup from 'yup';
import {product1, success, warning} from '@/assets';
import {geocodeByAddress} from 'react-google-places-autocomplete';
import moment from 'moment';
import toast from 'react-hot-toast';
import Image from 'next/image';

export function capitalizeEachWord(sentence) {
  let words = sentence?.split(' ');
  let capitalizedWords = words?.map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return capitalizedWords?.join(' ');
}

export const renderStars = (
  rate,
  spacingAmount = 0,
  smSpacingAmount = 0,
  xlSpacingAmount = 0,
) => {
  const fullStars = Math.floor(rate);
  const hasHalfStar = rate % 1 >= 0.5;

  return (
    <div
      className={`gap-${spacingAmount} flex sm:gap-${smSpacingAmount} xl:gap-${xlSpacingAmount}`}>
      {Array.from({length: 5}).map((_, index) => {
        if (index < fullStars) {
          return <IoStar key={index} className="text-persianIndigo" />;
        } else if (index === fullStars && hasHalfStar) {
          return <IoStarHalf key={index} className="text-persianIndigo" />;
        } else {
          return <IoStar key={index} className="text-thistle" />;
        }
      })}
    </div>
  );
};

const getImageUrl = (imageId, products) => {
  const image = products?.included?.find(img => img.id === imageId);
  return image ? image.attributes.original_url : null;
};

export const getImageSrc = (product, products) => {
  const imageId = product?.relationships?.images?.data[0]?.id;
  const imageUrl = imageId ? getImageUrl(imageId, products) : null;
  return imageUrl ? `${imageUrl}` : product1;
};

export function convertTo12HourFormat(time, locale = 'en') {
  const timeMatch = time.match(/(\d{2}):(\d{2})/);
  if (!timeMatch) return '';

  let [, hours, minutes] = timeMatch.map(Number);
  let period =
    hours >= 12
      ? locale === 'en'
        ? 'PM'
        : 'مساءًا'
      : locale === 'en'
        ? 'AM'
        : 'صباحًا';
  hours = hours % 12 || 12;
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
}

export const getLocalized12HourFormat = (time, locale) => {
  const localTime = moment.utc(time, 'HH:mm').local().format('HH:mm');
  return convertTo12HourFormat(localTime, locale);
};

export const convertEnglishNumbersToArabic = (number, locale = 'en') => {
  return number !== null && (number || number === 0) && locale === 'ar'
    ? number
        .toString()
        .replace(/[0-9]/g, c => String.fromCharCode(parseInt(c) + 1632))
    : number;
};

export const parseNumber = input => {
  const cleanedInput = input.replace(/[^0-9.-]/g, '');
  return parseFloat(cleanedInput);
};

const toastStyle = {
  border: '1px solid #382476',
  zIndex: 9050,
};

export const showSuccessToast = (message, iconSize = 40) => {
  toast.success(message, {
    duration: 10000,
    style: toastStyle,
    icon: (
      <Image src={success} width={iconSize} height={iconSize} alt="success" />
    ),
  });
};

export const showErrorToast = (message, iconSize = 40) => {
  toast.error(message, {
    style: toastStyle,
    icon: (
      <Image src={warning} width={iconSize} height={iconSize} alt="warning" />
    ),
  });
};

export function transformString(str) {
  str = str
    ?.replace(/%20/g, ' ')
    .replace(/\band\b/g, '&')
    .replace(/%26/g, '&');
  return str;
}

export const validateAddress = async address => {
  try {
    const results = await geocodeByAddress(address);
    return results?.length !== 0;
  } catch (error) {
    return false;
  }
};

export const rules = (type, test, t) => {
  switch (type) {
    case 'phoneNumber':
      return Yup.string()
        .required(t('phone_number_is_required'))
        .matches(
          /^(5|0|3|6|4|9|1|8|7)([0-9]{8})$/,
          t('phone_number_must_be_saudi_phone_number'),
        );
    case 'password':
      return Yup.string()
        .required(t('password_is_required'))
        .min(8, t('your_password_must_be_8_letters_long_or_more'))
        .matches(
          /^(?=.*[A-Za-z0-9])(?=.*[!@#$%^&*_-])[A-Za-z0-9!@#$%^&*_-]{8,}$/,
          t('your_password_must_have_at_least_one_special_characters'),
        );
    case 'newPassword':
      return Yup.string()
        .required(t('new_password_is_required'))
        .min(8, t('your_password_must_be_8_letters_long_or_more'))
        .matches(
          /^(?=.*[A-Za-z0-9])(?=.*[!@#$%^&*_-])[A-Za-z0-9!@#$%^&*_-]{8,}$/,
          t('your_password_must_have_at_least_one_special_characters'),
        );
    case 'name':
      return Yup.string().required(t('full_name_required'));
    case 'firstName':
      return Yup.string().required(t('first_name_is_required'));
    case 'lastName':
      return Yup.string().required(t('last_name_is_required'));
    case 'passwordConfirmation':
      return Yup.string()
        .oneOf(
          [Yup.ref('password'), null],
          t('password_confirmation_must_match'),
        )
        .required(t('password_confirmation_required'));
    case 'newPasswordConfirmation':
      return Yup.string()
        .oneOf(
          [Yup.ref('newPassword'), null],
          t('password_confirmation_must_match'),
        )
        .required(t('password_confirmation_required'));
    case 'gender':
      return Yup.string().required(t('please_select_your_gender'));
    case 'loginPhoneNumber':
      return Yup.string().required(t('enter_your_phone_number'));
    case 'loginPassword':
      return Yup.string().required(t('enter_your_password'));
    case 'address':
      return Yup.string()
        .required(t('address_is_required'))
        .test('is-valid-address', t('invalid_address'), async function (value) {
          const isValid = await validateAddress(value);
          return isValid;
        });
    case 'city':
      return Yup.string().required(t('city_is_required'));
    case 'requiredField':
      return Yup.string().required(t('this_field_is_required'));
    case 'updatePhoneNumber':
      return Yup.string()
        .required(t('this_field_is_required'))
        .matches(
          /^(5|0|3|6|4|9|1|8|7)([0-9]{8})$/,
          t('phone_number_must_be_saudi_phone_number'),
        );
    case 'email':
      return Yup.string()
        .email(t('invalid_email'))
        .required(t('email_is_required'));
    case 'lastName':
      return Yup.string();
    case 'address':
      return Yup.string();
    case 'aptnumber':
      return Yup.string();
    case 'district':
      return Yup.string().required(t('district_required'));
    case 'city':
      return Yup.string().required(t('city_required'));
    case 'cardNumber':
      return Yup.string()
        .matches(/^\d{16}$/, t('cardNumber_invalid'))
        .required(t('card_number_required'));
    case 'expiryDate':
      return Yup.string()
        .matches(
          /^(0[1-9]|1[0-2])\/\d{2}$/,
          t('expiry_date_is_invalid_ensure_the_date_is_in_the_format_mm_yy'),
        )
        .required(t('expiry_date_required'));
    case 'cvv':
      return Yup.string()
        .matches(/^\d{3}$/, t('cvv_invalid'))
        .required(t('cvv_required'));
    case 'cardOwner':
      return Yup.string().required(t('card_owner_required'));
    case 'nationalAddress':
      return Yup.string().matches(
        /^[A-Za-z]{4}\d{4}$/,
        t('nationalAddress_invalid'),
      );
  }
};

export const cities = [
  {key: 'riyadh', label: 'Riyadh', arLabel: 'الرياض'},
  {key: 'jeddah', label: 'Jeddah', arLabel: 'جدة'},
  {key: 'mecca', label: 'Mecca', arLabel: 'مكة'},
  {key: 'medina', label: 'Medina', arLabel: 'المدينة المنورة'},
  {key: 'ad dammām', label: 'Ad dammām', arLabel: 'الدمام'},
  {key: 'tabūk', label: 'Tabūk', arLabel: 'تبوك'},
  {key: 'al hufūf', label: 'Al hufūf', arLabel: 'الهفوف'},
  {key: 'al qaţīf', label: 'Al qaţīf', arLabel: 'القطيف'},
  {key: 'al ḩillah', label: 'Al ḩillah', arLabel: 'الحلة'},
  {key: 'aţ ţā’if', label: 'Aţ ţā’if', arLabel: 'الطائف'},
  {key: 'al jubayl', label: 'Al jubayl', arLabel: 'الجبيل'},
  {key: 'buraydah', label: 'Buraydah', arLabel: 'بريدة'},
  {key: 'ḩafr al bāţin', label: 'Ḩafr al bāţin', arLabel: 'حفر الباطن'},
  {key: 'yanbu', label: 'Yanbu', arLabel: 'ينبع'},
  {key: 'ḩā’il', label: 'Ḩā’il', arLabel: 'حائل'},
  {key: 'abhā', label: 'Abhā', arLabel: 'أبها'},
  {key: 'sakākā', label: 'Sakākā', arLabel: 'سكاكا'},
  {key: 'al qurayyāt', label: 'Al qurayyāt', arLabel: 'القريات'},
  {key: 'jāzān', label: 'Jāzān', arLabel: 'جازان'},
  {key: 'najrān', label: 'Najrān', arLabel: 'نجران'},
  {key: 'al wajh', label: 'Al wajh', arLabel: 'الوجه'},
  {key: 'arar', label: 'Arar', arLabel: 'عرعر'},
  {key: 'al bāḩah', label: 'Al bāḩah', arLabel: 'الباحة'},
  {key: 'tathlīth', label: 'Tathlīth', arLabel: 'تثليث'},
];

export const commonDisableCachingParameters = {
  refetchOnMountOrArgChange: false,
  refetchOnWindowFocus: false,
};
