export const getIsSignUpIsOpen = state => state.modalActions.isSignUpOpen;
export const getIsLogInOpen = state => state.modalActions.isLogInOpen;
export const getIsUpdateCreditCardModalOpen = state =>
  state.modalActions.isUpdateCreditCardOpen;
export const getIsDeleteCreditCardModalOpen = state =>
  state.modalActions.isDeleteCreditCardOpen;
export const getIsDeliveryMapModalOpen = state =>
  state.modalActions.isDeliveryMapModalOpen;
export const getIsOTPModalOpen = state => state?.modalActions?.isOtpModalOpen;
export const getotpModalState = state => state?.modalActions?.otpModalState;
export const getIsForgetPasswordModalOpen = state =>
  state?.modalActions?.isForgetPasswordModalOpen;

export const getDeleteUserAddressModal = state =>
  state?.modalActions?.isDeleteUserAddressModalOpen;

export const getIsNewPasswordUpdateModalOpen = state =>
  state?.modalActions?.isNewPasswordUpdateModalOpen;

export const getIsResetPasswordModalOpen = state =>
  state?.modalActions?.isResetPasswordModalOpen;

export const getChosenShoppingMethodId = state =>
  state?.modalActions?.chosenShoppingMethodId;
export const getIsGeneralOtpModalOpen = state =>
  state?.modalActions?.isGeneralOtpModalOpen;
